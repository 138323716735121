import React from "react"
import Layout from "../components/Layout"
import Seo from "../components/seo"
import { Link } from "gatsby"

const privacy = () => {
  return (
    <Layout>
      <Seo title="Termini e condizioni" />
      <div className="min-h-screen bg-gray-50 py-8 flex flex-col justify-center relative overflow-hidden lg:py-12">
        <div className="relative w-full px-6 py-12 prose prose-slate lg:prose-lg prose-sky bg-white shadow-xl shadow-slate-700/10 ring-1 ring-gray-900/5 md:max-w-3xl md:mx-auto lg:max-w-4xl lg:pt-16 lg:pb-28">
          <p className="my-6 text-xl md:text-3xl font-extrabold text-sky-900 uppercase tracking-wider">
          Termini e condizioni
          </p>
          <p>Il presente documento riporta i termini e le condizioni generali sulla base dei quali viene offerto agli Utenti l'uso della piattaforma web “Click’n’Pay” che offre servizi digitali in advertising nel campo del fintech, insurtech, utilities services (luce, gas e telefono) noleggio auto breve e lungo termine, investimenti, prenotazioni e biglietti viaggi ed eventi.</p>
<h3>2. <strong> Definizioni</strong></h3>
<p>Per consentire una completa comprensione e accettazione dei presenti termini e condizioni, i seguenti termini, al singolare e al plurale, avranno il significato di seguito indicato:</p>
<ol>
<li>
<p><strong>Titolare </strong><strong>di seguito anche per vie brevi “TP”</strong>:</p>
</li>
</ol>
<p>Tutor Point srls, Via dei Sette Metri 107/i, 00118 – Roma</p>
<p>Partita IVA &amp; CF 14641581005, indirizzo PEC <u><a href="mailto:TUTORPOINT@FLEXIPEC.IT">TUTORPOINT@FLEXIPEC.IT</a></u><u>”</u></p>
<ol>
<ul>
<li>
<p><strong>Sito</strong>: il sito web Click’n’Pay</p>
</li>
<li>
<p><strong>Utente</strong>: qualunque soggetto che accede e utilizza il Sito</p>
</li>
<li>
<p><strong>Condizioni</strong>: il presente contratto che disciplina i rapporti tra il Titolare e gli Utenti.</p>
</li>
</ul>
</ol>
<h3><strong>3.Informazioni dettagliate sull'offerta del Sito</strong></h3>
<p>Click’n’Pay è un portale gestito dal Titolare Tutor Point srls., che permette ai consumatori di ottenere informazioni relative a servizi di telecomunicazioni, energetici, servizi bancari e assicurativi, noleggio auto, biglietti e prenotazioni viaggi ed eventi per poi eventualmente entrare in contatto con le società o compagnie fornitrici o distributori di tali prodotti/servizi (nel seguito, congiuntamente, i “Partner”), secondo quanto meglio specificato nel seguito (il "Servizio"). Il Sito si articola in sezioni dedicate a specifici prodotti, ciascuna gestita e di titolarità di una società specializzata (le "Società Prodotto") ed in possesso dei prescritti requisiti regolamentari.</p>
<p>2.1<strong>. Il Servizio</strong></p>
<p>Il Servizio consiste nella presentazione di prodotti di telecomunicazione (internet e telefonia), energetici (fornitura energia elettrica e gas ad uso domestico), prodotti bancari (conti correnti, conti deposito, carte), noleggio auto (breve e lungo termine) volta al potenziale avvio di relazioni commerciali tra gli utenti del servizio e le società fornitrici di tali prodotti (gli “Operatori”).</p>
<p>La presentazione, promozione e commercializzazione delle offerte è effettuata da Click’n’Pay, sulla base di specifici mandati con gli Operatori (o sulla base di mandati da società delegate dagli Operatori stessi), ai fini della possibile conclusione dei contratti, oppure di accordi commerciali volti alla generazione di traffico e/o “lead” a beneficio degli Operatori.</p>
<p>Il Servizio prevede che, dopo che l’utente si sarà registrato sul Sito comunicando il suo indirizzo mail e il suo numero di cellulare come meglio specificato al punto 4, e avrà scelto uno specifico prodotto tra quelli proposti, sarà indirizzato al sito internet dell’Operatore prescelto o di un suo eventuale mandatario. Click’n’Pay agisce quale promoter per conto degli Operatori così come precedentemente indicato, al fine di presentare i prodotti e nel caso dell’acquisto del prodotto desiderato riconoscerà all’Utente un bonus secondo un “programma fedeltà” meglio definito al punto 7 del presente accordo. Gli eventuali contratti così conclusi saranno regolati dalle disposizioni previste dal Codice del Consumo.</p>
<p>Si precisa che il Titolare:</p>
<p>non è in alcun modo coinvolto nelle fasi di erogazione dei servizi e nella successiva gestione, è quindi esclusa ogni responsabilità connessa a queste due ultime fasi, di pertinenza esclusiva degli Operatori;</p>
<p>non è in grado di garantire la presentazione completa di tutti i prodotti e di tutte le offerte degli Operatori, vuoi per problemi di natura tecnico/informatica, vuoi per altri motivi; l'utente può comunque integrare l'analisi dei prodotti degli Operatori utilizzando i siti web degli Operatori stessi.</p>
<h3><strong>4.Ambito di applicazione delle Condizioni</strong></h3>
<p>L'uso del Sito comporta l'accettazione delle Condizioni da parte dell'Utente. Qualora l'Utente non intenda accettare le Condizioni e/o qualsiasi altra nota, avviso legale, informativa pubblicati o ivi richiamati non potrà utilizzare il Sito né i relativi servizi.</p>
<p>La TP si riserva di modificare unilateralmente secondo le disposizioni di legge le Condizioni. Eventuali modifiche saranno in vigore dal momento della loro pubblicazione sul Sito.</p>
<p>Prima di utilizzare il Sito, l'Utente è tenuto a leggere attentamente le Condizioni e a salvarle o stamparle per future consultazioni.</p>
<p>Il Titolare si riserva il diritto di variare a propria discrezione, in qualunque momento, l'interfaccia grafica del Sito, i Contenuti e la loro organizzazione, nonché ogni altro aspetto che caratterizza la funzionalità e la gestione del Sito, comunicando all'Utente, ove occorrenti, le relative istruzioni.</p>
<h3><strong>5.Registrazione</strong></h3>
<p>Per usufruire delle funzioni inserite all’interno del Sito è richiesta una registrazione da parte del futuro Utente compilando con informazioni veritiere e complete il form presente nella sezione “registrati” a cui si accede dal pulsante “registrazione gratuita”, dando espresso consenso alle regole della Privacy, disponibile all’indirizzo <u>https://web.tutorfi.it/index.php/privaçy-poliçy</u> e aderendo alle Condizioni del Sito.</p>
<p>L’Utente ha l’onere di custodire segretamente le credenziali di accesso generate, e l’utilizzo delle medesime è concesso esclusivamente allo stesso e non possono essere cedute a terzi per nessuna ragione. Qualora l’Utente venga a conoscenza di una azione fraudolenta di divulgazione delle stesse e/o di un uso indebito è tenuto ad informare tempestivamente la TP per le opportune azioni di tutela.</p>
<p>L’Utente garantisce che le informazioni fornite all’atto della registrazione risultano complete e veritiere e si impegna ad informare tempestivamente la TP di ogni variazione che interverrà nei dati dichiarati.</p>
<h3><strong>6.Cancellazione account e sospensione</strong></h3>
<p>L’Utente registrato può in qualsiasi momento cancellare autonomamente o richiedere la cancellazione del proprio account attraverso una domanda scritta da inviare all’indirizzo e-mail <u><a href="mailto:help@clicknpay.it">help@clicknpay.it</a></u> o chiamando al numero telefonico 06/5987.7456 esercitando quindi tale diritto.</p>
<p>In caso di violazione da parte dell’Utente delle condizioni o delle disposizioni di legge applicabili per l’erogazione dei servizi, il Titolare si riserva il diritto di sospendere o chiudere l’account dell’Utente in ogni momento e senza preavviso, riservandosi il risarcimento in caso di violazioni di legge.</p>
<h3><strong>7.Contenuti</strong></h3>
<p>I contenuti e/o i materiali disponibili sul Sito sono protetti dal diritto d'autore, dagli altri diritti attribuiti dalla legge sul diritto d'autore (diritti connessi, diritti sulle banche dati, etc.) e/o dalle altre leggi applicabili. Ogni utilizzazione dei contenuti e/o dei materiali disponibili sulla piattaforma web che non sia autorizzata ai sensi della Licenza e/o delle altre leggi applicabili è proibita.</p>
<p>Il Titolare concede all'Utente i diritti di seguito elencati a condizione che lo stesso accetti di rispettare i termini e le condizioni della Licenza.</p>
<p>Il Titolare consente all'Utente di distribuire, comunicare al pubblico, contenuti e/o materiali disponibili sul Sito con qualsiasi mezzo e formato, per fini commerciali, a condizione che l'Utente riconosca la paternità dell'opera alla TP e concordi con la stessa preventivamente le tipologie di iniziative che intraprenderà per il perseguimento dei fini.</p>
<h3><strong>8.Programma d Click’n’Pay Fidelity</strong></h3>
<p>Per ciascun servizio/prodotto erogato, è previsto un programma a punti, pubblicato nel Sito nelle relative sezioni di riferimento e le cui Condizioni sono definite al link <Link to="/programma-fidelity">Programma fidelity</Link>. La maturazione punti avverrà a fronte del traffico generato e tracciato tramite un codice ID legato all’Utente per l’acquisto per sé o la segnalazione a terzi dei prodotti/servizi presentati sul Sito, a condizione che il traffico generato sia valido e che gli Operatori abbiano provveduto al regolare versamento di quanto generato per l’intera somma a TP.</p>
<p>TP per ogni transazione andata a buon fine riceve dagli Operatori un ritorno economico che varia da prodotto a prodotto e da un Operatore all’altro. Questi ritorni saranno accumulati nell’Area riservata dell’Utente registrato e convertiti in punti. La transazione si intende definita solo al momento dell’avvenuto pagamento da parte dell’Operatore. Solo a transazione definita, dunque, i punti verranno validati e saranno visibili nell’Area riservata dell’Utente. Ogni punto corrisponde a 10 centesimi di euro. Raggiunta la quota minima di 250 punti, l’Utente potrà richiederne il riscatto attraverso la sezione “Riscatta premi” nella quale sarà possibile scegliere dei buoni acquisto per beni e servizi all'interno di un circuito limitato di negozi e/o siti aderenti a siti e-commerce di fornitori terzi. Sugli importi accumulati e non pagati non si applicheranno interessi legali e/o di mora.</p>
<p>Nulla di quanto incluso nelle presenti condizioni creerà o intende creare un rapporto di subordinazione tra TP e l’Utente.</p>
<h3><strong>9.Esclusione della garanzia</strong></h3>
<p>Il Sito viene fornito "così come è" e "come è disponibile" e il Titolare non fornisce alcuna garanzia esplicita o implicita in relazione al Sito, né fornisce alcuna garanzia che Sito potrà soddisfare le esigenze degli Utenti o che non avrà mai interruzioni o sarà priva di errori o che sarà priva di virus o bug.</p>
<p>Il Titolare si adopererà per assicurare che il Sito sia disponibile ininterrottamente 24 ore al giorno, ma non potrà in alcun modo essere ritenuto responsabile se, per qualsiasi motivo, il Sito non fosse accessibile e/o operativo in qualsiasi momento o per qualsiasi periodo. L'accesso al Sito può essere sospeso temporaneamente e senza preavviso in caso di guasto del sistema, manutenzione, riparazioni o per ragioni del tutto estranee alla volontà del Titolare o per eventi di forza maggiore.</p>
<h3><strong>10.Limitazione della Responsabilità</strong></h3>
<p>Il Titolare non potrà ritenersi responsabile verso l'Utente, salvo il caso di dolo o colpa grave, per disservizi o malfunzionamenti connessi all'utilizzo della rete internet al di fuori del controllo proprio o di suoi fornitori.</p>
<p>Il Titolare non sarà inoltre responsabile in merito a danni, perdite e costi subiti dall'Utente a seguito della mancata esecuzione del contratto per cause a lui non imputabili</p>
<p>Il Titolare non sarà responsabile per:</p>
<ul>
<li>
<p>eventuali perdite di opportunità commerciale e qualsiasi altra perdita, anche indiretta, eventualmente subita dall'Utente che non siano conseguenza diretta della violazione del contratto da parte del Titolare</p>
</li>
<li>
<p>errato o inidoneo utilizzo del Sito da parte degli Utenti o di terzi</p>
</li>
</ul>
<h3><strong>11. Forza maggiore</strong></h3>
<p>Il Titolare non potrà essere considerato responsabile per il mancato o ritardato adempimento delle proprie obbligazioni, per circostanze al di fuori del controllo ragionevole del Titolare dovuti ad eventi di forza maggiore o, comunque, ad eventi imprevisti ed imprevedibili e, comunque, indipendenti dalla sua volontà.</p>
<p>L'adempimento delle obbligazioni da parte del Titolare si intenderà sospeso per il periodo in cui si verificano eventi di forza maggiore.</p>
<p>Il Titolare compirà qualsiasi atto in suo potere al fine di individuare soluzioni che consentano il corretto adempimento delle proprie obbligazioni nonostante la persistenza di eventi forza maggiore.</p>
<h3><strong>12.Collegamento a siti di terzi</strong></h3>
<p>Il Sito contiene collegamenti a siti/applicazioni di terzi. Il Titolare non esercita alcun controllo su di essi e, pertanto, non è in alcun modo responsabile per i contenuti di questi siti/applicazioni.</p>
<p>Alcuni di questi collegamenti rinviano a siti/applicazioni di terzi che forniscono servizi attraverso il Sito. ln questi casi, ai singoli servizi si applicheranno le condizioni generali per l'uso del sito/applicazione e per la fruizione del servizio stesso predisposte dai terzi, rispetto alle quali il Titolare non assume alcuna responsabilità.</p>
<h3><strong>13.Privacy</strong></h3>
<p>La tutela e il trattamento dei dati personali avverranno in conformità all'Informativa Privacy, che può essere consultata alla pagina <u>https://web.tutorfi.it/index.php/privaçy-poliçy</u></p>
<h3><strong>14.Legge applicabile e foro competente</strong></h3>
<p>Le Condizioni sono soggette alla legge italiana. Per ogni controversia relativa al Sito, esecuzione e interpretazione delle presenti Condizioni è competente il foro del luogo in cui ha sede il Titolare.</p>
        </div>
      </div>
    </Layout>
  )
}

export default privacy
